import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";
import {configStore} from "@/store/configStore";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage, deleteToken} from "firebase/messaging"
import {authStore} from "@/store/authStore";

export const notificationStore = defineStore('notification', {
    state: () => ({
        messages: []
    }),
    getters: {
      unread(){
          return this.messages.filter(m => m.unread === 1).length
      }
    },
    actions: {
        clearNotification(){
            aSyncData('notifications/read')
                .catch(e => {console.error(e)})
        },
        openLink(link){
            const config = configStore()
            if(link && link.length > 0){
                const linkSmall = link.toLowerCase()
                if(linkSmall.startsWith('http://' + config.projectURLRaw) || linkSmall.startsWith('https://' + config.projectURLRaw) ||linkSmall.startsWith( + config.projectURLRaw)){
                    this.$router.push(link)
                }else{
                    window.open(linkSmall.startsWith('http') ? link : 'https://' + link, '_system')
                }
            }else{
                this.$router.push({name: 'Notifications'})
            }
        },
        loadNotification(){
            const auth = authStore()
            const config = configStore()
            return new Promise(resolve => {
                if(!auth.isAuth) resolve()
                aSyncData('notifications/getNotifications')
                    .then(r => {
                        if(r.status === 250){
                            this.messages = []
                            r.data.forEach(nT => {
                                try {
                                    this.messages.push({
                                        title: nT.data.notification.title,
                                        body: nT.data.notification.body,
                                        link: nT.data.data.link,
                                        unread: nT.unread,
                                        date: nT.date
                                    })
                                }catch (e) {

                                }
                            })
                            if(config.cordova) cordova.plugins.firebase.messaging.setBadge(this.unread)
                        }

                    })
                    .catch(e => {console.error(e)})
                    .finally(() => {
                        resolve()
                    })
            })
        }
    }
})